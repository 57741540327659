.qoidalar {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.qoidalar h1 {
    text-align: center;
    font-family: sans-serif;
}

.qoidalar_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #333;
    text-transform: uppercase;
}