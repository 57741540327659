@import url("https://fonts.googleapis.com/css2?family=Gruppo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.fakultetCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-size: 100%;
  background-position: center;
  overflow: hidden;
}

.fakultetConBtn {
  width: 18.6vw;
  height: 5.44vw;
  background: transparent;
  border: none;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.fakultetConBtn p {
  margin: 0vw 0;
  padding: 0;
}

.fakultetConBtn p:nth-child(1) {
  max-width: 80%;
  color: white;
  font-weight: 600;
  font-size: 0.74vw;
  text-transform: uppercase;
  margin-top: 0.74vw;
}

.fakultetConBtn p:nth-child(2) {
  color: white;
  font-weight: 500;
  font-size: 0.76vw;
}

.fakultetConBtns {
  display: flex;
  height: max-content;
  overflow: unset;
}

.fakultetConBtns button {
  cursor: pointer;
}

.fakultetConBtns button:nth-child(1) {
  transform: translateY(-3.12vw) translateX(6.24vw) scale(0.5);
  filter: blur(0.31vw);
}

.fakultetConBtns button:nth-child(2) {
  transform: translateY(3.12vw);
}

/* active line 1 */

.fakultetConBtns .fakultetConBtn-1:nth-child(2)::before {
  content: "";
  position: absolute;
  height: 5.2vw;
  width: 28.31vw;
  background-color: transparent;
  top: 4.75vw;
  left: 9.25vw;
  border-bottom: 0.09vw solid #fb3505;
  border-left: 0.09vw solid #fb3505;
  animation: animLine 0.5s ease-in-out;
}

.fakultetConBtns .fakultetConBtn-1:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 9.96vw;
  left: 32.4vw;
  width: 5.2vw;
  height: 15.98vw;
  border-right: 0.09vw solid #fb3505;
  animation: animLine 1s ease-in-out;
}

.title_fakultet {
    text-align: center;
    font-family: "zonaProBold", sans-serif;
    font-size: 2.813vw;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* active line 2 */

.fakultetConBtns .fakultetConBtn-2:nth-child(2)::before {
  content: "";
  position: absolute;
  height: 7.25vw;
  width: 9.78vw;
  background-color: transparent;
  top: 2.65vw;
  left: 27.85vw;
  border-bottom: 0.09vw solid #fb3505;
  border-left: 0.09vw solid #fb3505;
  animation: animLine 0.5s ease-in-out;
}

.fakultetConBtns .fakultetConBtn-2:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 9.96vw;
  left: 32.4vw;
  width: 5.2vw;
  height: 11.98vw;
  border-right: 0.09vw solid #fb3505;
  animation: animLine 1s ease-in-out;
}

/* active line 3 */

.fakultetConBtns .fakultetConBtn-3:nth-child(2)::before {
  content: "";
  position: absolute;
  height: 7.9vw;
  width: 8.85vw;
  background-color: transparent;
  top: 2vw;
  left: 37.6vw;
  border-bottom: 0.09vw solid #fb3505;
  border-right: 0.09vw solid #fb3505;
  animation: animLine 0.5s ease-in-out;
}

.fakultetConBtns .fakultetConBtn-3:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 9.96vw;
  left: 32.4vw;
  width: 5.2vw;
  height: 11.98vw;
  border-right: 0.09vw solid #fb3505;
  animation: animLine 1s ease-in-out;
}

/* active line 4 */

.fakultetConBtns .fakultetConBtn-4:nth-child(2)::before {
  content: "";
  position: absolute;
  height: 3.2vw;
  width: 27.5vw;
  background-color: transparent;
  top: 5.2vw;
  left: 37.6vw;
  border-bottom: 0.09vw solid #fb3505;
  border-right: 0.09vw solid #fb3505;
  animation: animLine 0.5s ease-in-out;
}

.fakultetConBtns .fakultetConBtn-4:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 8.4vw;
  left: 32.4vw;
  width: 5.2vw;
  height: 8.98vw;
  border-right: 0.09vw solid #fb3505;
  animation: animLine 1s ease-in-out;
}

/* line animation  */

@keyframes animLine {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fakultetConBtns button:nth-child(3) {
  transform: translateY(0.62vw);
}

.fakultetConBtns button:nth-child(4) {
  transform: translateY(0);
}

.fakultetConBtns button:nth-child(5) {
  transform: translateY(3.76vw);
}

.fakultetConBtns button:nth-child(6) {
  transform: translateY(-0.62vw) translateX(-10.32vw) scale(0.5);
  filter: blur(0.31vw);
}

.categoryBtnsChil {
  position: absolute;
  top: 47.46vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.04vw;
  animation: animationCategory 0.5s ease-in-out;
  z-index: 9999999;
}

@keyframes animationCategory {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.categoryBtnsChil button {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.23vw 1.75vw;
  border-radius: 3.56vw;
  border: none;
  background: #f1f1f1;
  font-size: 0.94vw;
  color: black;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
}

.categoryBtnsChil .categoryBtnsChilInner {
  display: flex;
  gap: 0.52vw;
}

.categoryBtnsChil .categoryBtnsChilInner .closeBtn {
  width: 3.33vw;
  height: 3.33vw;
  border-radius: 50%;
  font-family: "Gruppo", serif;
  font-size: 1.15vw;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mashinasozlikCat_1_con {
  width: 80vw;
  overflow: hidden;
  display: flex;
}

.mashinasozlikCat_1 {
  width: 80vw;
  height: 66.5vw;
  background-color: white;
  transform: translateY(20.83vw);
  position: absolute;
  left: 10%;
  right: 10%;
  border-top-left-radius: 2.083vw;
  border-top-right-radius: 2.083vw;
  background-image: url(./shestnna/blackBg.png);
  background-size: 100%;
  background-position-y: 26.04vw;
  background-position-x: 0;
  background-repeat: no-repeat;
  overflow: hidden;
  box-shadow: 0px 44px 156px -16px #2727274d;
}

.testDivMashinasozlik {
  position: relative;
  width: 100%;
  height: 62.5vw;
  bottom: 0;
}

#line {
  width: 34.32vw;
  position: absolute;
  left: 20.83vw;
  bottom: 14.58vw;
  z-index: 1;
}

#rounded {
  width: 2.34vw;
  position: absolute;
  right: 23.9vw;
  top: 20vw;
  animation: rotete 15s infinite linear;
}

#whitelineshet {
  width: 16.206vw;
  position: absolute;
  top: 13.28vw;
  right: 16.41vw;
  z-index: 0;
  animation: rotete 15s infinite linear;
}

#redline {
  width: 6.1vw;
  position: absolute;
  bottom: 15.1vw;
  left: 21.35vw;
  animation: rotete 15s infinite linear;
}

#roundedRd {
  width: 1.3vw;
  position: absolute;
  bottom: 17.45vw;
  left: 23.7vw;
  animation: rotete 15s infinite linear;
}

#redshet2 {
  width: 8.39vw;
  position: absolute;
  bottom: 0.52vw;
  left: 15.89vw;
  animation: rotete 15s infinite linear;
}

#redshet3 {
  width: 10.63vw;
  position: absolute;
  bottom: 7.03vw;
  left: 10.05vw;
  animation: roteteReverse 15s infinite linear;
}

#redshet4 {
  width: 18.39vw;
  position: absolute;
  bottom: 14.58vw;
  left: -1.56vw;
  animation: rotete 27.95s infinite linear;
}

#redshet5 {
  width: 10.31vw;
  position: absolute;
  bottom: 24.22vw;
  left: 14.84vw;
  animation: roteteReverse 15s infinite linear;
}

#roundedRd2 {
  width: 2.34vw;
  position: absolute;
  bottom: 22.92vw;
  left: 6.51vw;
}

#roundedRd3 {
  width: 1.56vw;
  position: absolute;
  bottom: 11.72vw;
  left: 14.58vw;
}

#roundedRd4 {
  width: 1.56vw;
  position: absolute;
  bottom: 4.01vw;
  left: 19.27vw;
}

#roundedRd5 {
  width: 1.56vw;
  position: absolute;
  bottom: 28.65vw;
  left: 19.27vw;
}

#white1 {
  width: 8.7vw;
  position: absolute;
  right: 23.96vw;
  top: 5.99vw;
  animation: roteteReverse 8s infinite linear;
}

#whitelineshet2 {
  width: 16.823vw;
  position: absolute;
  right: 10.34vw;
  top: -5.5vw;
  animation: rotete 15s infinite linear;
}

#rounded2 {
  width: 2.222vw;
  position: absolute;
  right: 17.62vw;
  top: 1.56vw;
}

#whitesheet2 {
  width: 10.659vw;
  position: absolute;
  right: 3.13vw;
  top: 4.95vw;
  animation: roteteReverse 8s infinite linear;
}

#rounded3 {
  width: 1.56vw;
  position: absolute;
  right: 7.71vw;
  top: 9.4vw;
}

#whitesheet3 {
  width: 10.659vw;
  position: absolute;
  right: -4vw;
  top: 11.24vw;
  animation: rotete 8s infinite linear;
}

#rounded4 {
  width: 1.56vw;
  position: absolute;
  right: 0.52vw;
  top: 15.67vw;
}

.mashinasozlikCat_1_text1 {
  width: 50%;
  color: #000000;
  font-size: 1.55vw;
  font-family: "Montserrat", serif;
  padding: 2.6vw;
  line-height: 1.79vw;
  font-weight: 600;
}

.mashinasozlikCat_1_redText {
  width: 36.563vw;
  height: 24.042vw;
  position: absolute;
  bottom: 0;
  right: 2.083vw;
  background-image: url(./shestnna/bgText.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mashinasozlikCat_1_redText p {
  margin-left: 10%;
  width: 80%;
  color: #dedfe0;
  font-size: 1.25vw;
  font-family: "Montserrat", serif;
  padding: 2.6vw;
  line-height: 1.79vw;
  font-weight: 400;
}

.mashinasozlikCat_1_info {
  width: 80vw;
  position: absolute;
  left: 10%;
  right: 10%;
  bottom: -205vw;
  border-bottom-left-radius: 2.083vw;
  border-bottom-right-radius: 2.083vw;
  box-shadow: 0px 44px 156px -16px #2727274d;
}

@media screen and (max-width: 985px) {
    .mashinasozlikCat_1_info {
        bottom: -225vw;
    }
}
/* @media screen and (max-width: 985px) {
    .mashinasozlikCat_1_info {
        bottom: -130vw;
    }
}

@media screen and (max-width: 1140px) {
    .mashinasozlikCat_1_info {
        bottom: -205vw;
    }
}

@media screen and (max-width: 2800px) {
    .mashinasozlikCat_1_info {
        bottom: -105vw;
    }
}

@media screen and (max-width: 3900px) {
    .mashinasozlikCat_1_info {
        bottom: -207vw;
    }
} */


.mashinasozlikCat_1_info_head {
  height: 33.125vw;
  background: linear-gradient(to left, #dd0c0cc9, #671500c0),
    url(./shestnna/injinerBg.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.mashinasozlikCat_1_info_head2 {
  height: 33.125vw;
  background: linear-gradient(to left, #dd0c0cc9, #671500c0),
    url(./shestnna/injiner2.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.mashinasozlikCat_1_info_head3 {
  height: 38.125vw;
  background: linear-gradient(to left, #dd0c0cc9, #671500c0),
    url(./shestnna/injinerBg3.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.mashinasozlikCat_1_info_head4 {
  height: 38.125vw;
  background: linear-gradient(to left, #dd0c0cc9, #671500c0),
    url(./shestnna/injiner3.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.mashinasozlikCat_1_info_Two {
  height: 60vw;
  background: #ed1c24;
  background-image: none;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
}

.mashinasozlikCat_1_info_head h1 {
  width: 50%;
  color: white;
  font-size: 3.509vw;
  font-family: "Montserrat", serif;
  font-weight: 400;
  padding: 4.167vw;
}

.mashinasozlikCat_1_info_head p {
  width: 80%;
  padding: 1vw 4.167vw;
  font-size: 1.246vw;
  font-family: "Montserrat", serif;
  color: #ffffff;
}

.mashinasozlikCat_1_info_list {
  display: flex;
  flex-direction: column;
  background: url(./shestnna/shaxmatBg.png), #fb3505;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 50px;
  border-bottom-left-radius: 2.083vw;
  border-bottom-right-radius: 2.083vw;
}

.mashinasozlikCat_1_info_list2 {
  background: url(./shestnna/shaxmatBgwhite.png);
}

.mashinasozlikCat_1_info_list3 {
  background: black;
}

.mashinasozlikCat_1_info_list4 {
  background: white;
}

#userImgThereFakultet {
  width: 26.042vw;
  position: absolute;
  right: 0;
  bottom: 0;
}

.mashinasozlikCat_1_info_list_title {
  display: flex;
  align-items: center;
  gap: 2.083vw;
  padding: 1.4vw 3.125vw;
}

.mashinasozlikCat_1_info_list_title img {
  width: 10.26vw;
  height: 10.26vw;
}

.mashinasozlikCat_1_info_list_title p {
  width: 50%;
  font-size: 2.272vw;
  color: #ffffff;
  font-family: "Montserrat", serif;
  text-transform: uppercase;
}

.mashinasozlikCat_1_info_list2 .mashinasozlikCat_1_info_list_title p {
  width: 50%;
  font-size: 2.272vw;
  color: #000;
  font-family: "Montserrat", serif;
  text-transform: uppercase;
}

.mashinasozlikCat_1_info_list3 .mashinasozlikCat_1_info_list_title p {
  color: white;
}

.mashinasozlikCat_1_info_list2 ul {
  transform: translateX(-10.417vw);
}

.mashinasozlikCat_1_info_list ul {
  display: flex;
  flex-direction: column;
  gap: 1.042vw;
}

.mashinasozlikCat_1_info_list ul li {
  display: flex;
  flex-direction: row;
  gap: 0.921vw;
}

.mashinasozlikCat_1_info_list ul li p {
  color: #ffffff;
  font-family: "Montserrat", serif;
  font-size: 1.198vw;
}

.mashinasozlikCat_1_info_list2 ul li p {
  color: #000;
  font-family: "Montserrat", serif;
  font-size: 1.198vw;
  width: 45vw;
}

.mashinasozlikCat_1_info_list3 ul li p {
  color: #fff;
  font-family: "Montserrat", serif;
  font-size: 1.198vw;
}

.mashinasozlikCat_1_info_list ul li img {
  width: 12.5vw;
}

.fakultetOpenModal_btn {
    width: 14.375vw;
    height: 3.75vw;
    border-radius: 3.75vw;
    cursor: pointer;
    background: radial-gradient(127.68% 423.02% at 31.82% -13.39%, #FF9B00 12.5%, #ED1C24 100%);
    color: white;
    font-size: 0.833vw;
    border: none;
    box-shadow: 0px 0.781vw 1.875vw 0px #F657144D;
    font-family: "Montserrat", serif;
    font-weight: 600;
    position: absolute;
    bottom: 14.583vw;
    left: 13.542vw;
    cursor: pointer;
    z-index: 2;
}

.fakultetOpenModal_btn:active {
    transform: scale(0.9);
}
/* modal css */

.modalFakultet {
    margin: 0 auto;
    width: 52.5vw;
    height: 29vw;
    border-radius: 2.083vw;
    position: fixed;
    background-color: #FFFFFF;
    z-index: 99999999;
    padding: 5vw 3vw; 
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
}

.modalFakultet_closeBtn {
    display: flex;
    gap: 1.042vw;
}

.modalFakultet_closeBtn button:nth-child(1) {
    flex: 1;
    height: 3.333vw;
    border-radius: 2.5vw;
    background: #FB3505;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 1.042vw;
    font-family: "Montserrat", serif;
    border: none;
    cursor: pointer;
}

.modalFakultet_closeBtn button:nth-child(2) {
    width: 3.333vw;
    height: 3.281vw;
    border-radius: 50%;
    background: #F1F1F1;
    border: none;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25vw;
    cursor: pointer;
}

.modalFakultet_list {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.modalFakultet_list li {
    display: flex;
    align-items: center;
    gap: 20px;
}

.modalFakultet_list li p {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}

.modalFakultet_list li span {
    height: 1.3px;
    flex: 1;
    background: #ED1C24;
}

.modalFakultet_list li b {
    font-size: 16px;
    color: #000000;
}


@keyframes rotete {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes roteteReverse {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.FakturaAbsolute {
  position: absolute;
  left: 10%;
  right: 10%;
}
.FakultetOne {
  width: 80vw;
  transform: translateY(20.83vw);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  background-color: white;
  box-shadow: 0vw 2.29vw 8.13vw -0.83vw #2727274d;
  border-radius: 2.08vw;
  position: relative;
}
.FakultetOneSection1 {
  width: 72vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  padding: 4vw 5vw;
}

.FakultetOneSection1 h1 {
  width: 100%;
  margin: 0vw;
  text-align: start;
  font-family: "zonaProBold", sans-serif;
  font-weight: 400;
  font-size: 4vw;
  line-height: 5vw;
  letter-spacing: 0%;
}

.FakultetOneBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 29vw;
  border-radius: 1vw;
  gap: 1.5vw !important;
  padding: 2vw;
  background: rgb(255, 204, 0, 0.788);
  background: linear-gradient(
    150deg,
    rgba(255, 204, 0, 0.788) 0%,
    rgba(237, 237, 237, 1) 45%
  );
}

.FakultetOneBoxes {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FakultetOneBoxesOne {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}
.FakultetOneBox p {
  font-family: "zonaProBold", sans-serif;
  margin: 0;
  width: 18vw;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 1.8vw;
  letter-spacing: 0%;
}

.FakultetOneBox span {
  font-family: "zonaProBold", sans-serif;
  margin: 0;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.4vw;
  letter-spacing: 0%;
}
.FakultetOneBoxMini {
  height: 18.9vw !important;
}

.FakultetOneBoxMini img {
  width: 5.5vw;
  height: 5.5vw;
}

.FakultetOneBoxBig img {
  width: 16vw;
  height: 16vw;
}

.FakultetOneBoxesTwo {
  gap: 2vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.FakultetOneSectio1Borders {
}
.borderOne {
  width: 81.2vw;
  position: absolute;
  top: 0vw;
  left: 2vw;
  z-index: 9999;
}
.borderTwo {
  width: 40.8vw;
  position: absolute;
  top: 0vw;
  right: -1vw;
  z-index: 9999;
}
.FakultetTwoBoxesMain {
  width: 100%;
}
.FakultetTwoBoxes {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5vw;
  top: 20vw;
}
.FakultetTwoBox {
  width: 15.5vw;
  height: 32vw;
  border-radius: 1vw;
  background: #ebebeb;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  transition: all 0.3s ease;
}
.FakultetTwoBox img {
  width: 14vw;
}

.modalButtoTwo {
  margin-top: 2.6vw;
  width: 16vw !important;
  height: 3.8vw !important;
}

.FakultetOneSection1Header {
  width: 100%;
  height: 33vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.FakultetOneSection1HeaderTitles p {
  font-family: "zonaProBold", sans-serif;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 1.5vw;
  letter-spacing: 0%;
}

.FakultetOneSection1HeaderTitles ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1vw;
}

.FakultetOneSection1HeaderTitles li {
  font-family: "zonaProBold", sans-serif;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.1vw;
  letter-spacing: 0%;
  list-style-type: disc;
}

.FakultetTwoBox:hover {
  transform: translateY(-0.5vw);
  transition: all 0.3s ease;
}

.FakultetTwoBoxTexts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.2vw;
}
.FakultetTwoBoxTexts p {
  font-family: "zonaProBold", sans-serif;
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 1.3vw;
  letter-spacing: 0%;
  text-align: center;
}

.FakultetTwoBoxTexts ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1vw;
}

.FakultetTwoBoxTexts li {
  font-family: "zonaProBold", sans-serif;
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1vw;
  letter-spacing: 0%;
  list-style-type: disc;
}

.FakultetThreeBoxes {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FakultetThreeBoxesOne {
  display: flex;
  flex-direction: column;
  gap: 9vw;
}

.FakultetThreeBox {
  width: 23.5vw;
  height: 10vw;
  background: #fb3505;
  padding: 1.8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.8vw;
  position: relative;
}
.FakultetThreeBoxesTwo {
  align-items: flex-end;
}
.FakultetThreeBoxesTwo img {
  left: 2vw;
}
.FakultetThreeBox img {
  position: absolute;
  width: 4.5vw;
  height: 4.5vw;
  top: 1.2vw;
  right: 2vw;
}
.FakultetThreeBoxesTwo p {
  text-align: end;
}
.FakultetThreeBoxesTwo span {
  text-align: end;
}
.FakultetThreeBox p {
  font-family: "zonaProBold", sans-serif;
  width: 14vw;
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.2vw;
  letter-spacing: 0%;
  margin: 0;
  color: white;
}

.FakultetThreeBox span {
  font-family: "zonaProBold", sans-serif;
  margin: 0;
  font-weight: 400;
  font-size: 1vw;
  line-height: 0.9vw;
  letter-spacing: 0%;
  color: white;
}
.line1 {
  width: 31vw;
  position: absolute;
  top: 16.3vw;
  left: -0.6vw;
}
.line2 {
  width: 95vw;
  position: absolute;
  top: 24vw;
  right: -8vw;
}
.line3 {
  width: 96vw;
  position: absolute;
  top: 37.5vw;
  right: -9.7vw;
}
.line4 {
  width: 60vw;
  position: absolute;
  top: 48.8vw;
  right: 2.9vw;
}

.lineBtn {
  width: 19vw;
  position: absolute;
  top: 52vw;
  right: 14.4vw;
}
.modalBtnThree {
  width: 16.5vw !important;
  height: 3.8vw !important;
  position: absolute;
  top: 56vw;
  right: 10vw;
}

.cloudF {
  width: 4vw;
  height: 10vw;
  position: absolute;
  top: 30vw;
  left: 0vw;
  background-color: white;
}
.modalButtonOrange {
  width: 25vw;
  height: 4vw;
  border-radius: 3vw;
  padding: 1.2vw 1.7vw;
  background: radial-gradient(
    127.68% 423.02% at 31.82% -13.39%,
    #ff9b00 12.5%,
    #ed1c24 100%
  );
  box-shadow: 0px 0.8vw 2vw 0px #f657144d;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 999999;
}
.modalButtonOrange p {
  font-family: "zonaProBold", sans-serif;
  font-weight: 700;
  font-size: 1vw;
  line-height: 1.1vw;
  letter-spacing: 0%;
  color: #ffffff;
  text-align: center;
}
.modalButtonOrange:active {
  scale: 80%;
  transition: all 0.3s ease;
}
.mashinasozlikCat_1_info_list2 ul li p{

}